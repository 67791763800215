import React, { Component } from 'react';
import $ from 'jquery';
import { ajax_wrapper, sort_objects } from 'functions';
import { CreateSync } from 'pages';
import {
    Alert,
    Button,
    Modal,
    TextInput,
    TextArea,
    Select,
    MultiSelect,
    Form,
    ItemPool,
} from 'library';

class NewSplitCard extends Component {
    render() {
        let remove_button = [];
        if (this.props.split_count > 1) {
            remove_button.push(
                <Button
                    type="primary"
                    onClick={this.props.remove_split}
                    className="dark-button new-project-button"
                >
                    -
                </Button>,
            );
        }

        return (
            <div>
                <p
                    style={{
                        paddingLeft: '10px',
                        paddingTop: '10px',
                        marginBottom: '2px',
                        fontSize: '14px',
                        fontStyle: 'italic',
                    }}
                >
                    Add New Split...
                </p>
                <div
                    className="simple-card project-card flex-container"
                    style={{ width: '80px', marginTop: '0px' }}
                >
                    <Button
                        type="primary"
                        onClick={this.props.new_split}
                        className="dark-button new-project-button"
                    >
                        +
                    </Button>
                    {remove_button}
                </div>
            </div>
        );
    }
}

const NEW_PROJECT_REQUIRED = {
    name: 'Project Name',
    client_id: 'Client',
    taxonomy_id: 'Taxonomy',
    export_format: 'Export Format',
};

export default class NewProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            context: {
                clients: [],
                taxonomies: [],
                taggers: [],
            },

            name: '',
            export_format: '',
            taggers: [],
            client_id: '',
            taxonomy_id: '',
            splits_display: false,
            taxonomy_tagtypes_lookup: {},
            timestamp: new Date().getTime(),
            tag_type_options: [],
            split_count: 1,
            client_default_button_text: 'Save Client Default',
            required: [],
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/new_project_context/`, {}, (value) => {
            let taxonomy_tagtypes_lookup = {};
            for (let taxonomy of value['taxonomies']) {
                taxonomy_tagtypes_lookup[taxonomy['id']] = [
                    ...taxonomy['tag_types'],
                ];
            }
            let client_defaults = { ...value['client_defaults'] };

            this.setState({
                loaded: true,
                context: value,
                taxonomy_tagtypes_lookup: taxonomy_tagtypes_lookup,
                client_defaults: client_defaults,
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.client_id !== '' &&
            this.state.client_id !== prevState.client_id &&
            Object.keys(this.state.client_defaults).includes(
                this.state.client_id,
            )
        ) {
            this.load_client_default(this.state.client_id);
        } else if (
            this.state.taxonomy_id !== '' &&
            this.state.taxonomy_id !== prevState.taxonomy_id
        ) {
            // default_loaded prevents overwriting default when taxonomy
            // state change triggers reload. Once taxonomy change is
            // triggered by client change (first time), loading taxonomy
            // default is reactivated
            //
            // WILL CAUSE ISSUE IF CLIENT DEFAULT DOES NOT HAVE TAXONOMY

            if (this.state.default_loaded) {
                this.setState({ default_loaded: false });
            } else {
                this.load_taxonomy_default(this.state.taxonomy_id, true);
            }
        }
    }

    load_client_default = (client_id) => {
        let client_default = this.state.client_defaults[client_id];
        let split_count = client_default['split_count'];

        let data = {
            split_count: split_count,
            splits_display: true,
            default_loaded: true,
        };

        if (Object.keys(client_default).includes('taxonomy')) {
            data['taxonomy_id'] = client_default['taxonomy'];
            this.load_taxonomy_default(data['taxonomy_id'], false);
        }
        if (Object.keys(client_default).includes('export_format')) {
            data['export_format'] = client_default['export_format'];
        }

        for (let i in [...Array(split_count).keys()]) {
            let split_num = parseInt(i) + 1;
            data[`split_${split_num}_tag_types`] = client_default[
                'special_fields_by_split'
            ][split_num].concat(
                client_default['tag_types_ids_by_split'][split_num],
            );
        }

        this.setState(data);
    };

    load_taxonomy_default = (taxonomy_id, select_all) => {
        let tag_type_options = [];
        let tag_type_lookup = this.state.taxonomy_tagtypes_lookup[taxonomy_id];

        for (let field of this.state.context['special_fields']) {
            tag_type_options.push({
                text: field['display_name'],
                value: field['name'],
                class: 'tag-type-pool-button-special',
            });
        }

        for (let tag_type of tag_type_lookup) {
            tag_type_options.push({
                text: tag_type['name'],
                value: tag_type['id'],
            });
        }
        this.setState({
            tag_type_options: tag_type_options,
            select_all: select_all,
            splits_display: true,
        });
    };

    submit = (add_songs) => {
        let url = `/api/create_project/`;

        let data = {
            name: this.state.name,
            client_id: this.state.client_id,
            taxonomy_id: this.state.taxonomy_id,
            export_format: this.state.export_format,
            project_notes: this.state.project_notes,
            split_count: this.state.split_count,
        };

        let required = [];
        for (let key in data) {
            if (key in NEW_PROJECT_REQUIRED && !data[key]) {
                required.push(`Missing ${NEW_PROJECT_REQUIRED[key]}`);
            }
        }

        for (let i in [...Array(this.state.split_count).keys()]) {
            i = parseInt(i) + 1;
            data[`split_${i}_taggers`] = this.state[`split_${i}_taggers`];
            data[`split_${i}_tag_types`] = this.state[`split_${i}_tag_types`];

            if (!this.state[`split_${i}_taggers`]) {
                required.push(`Missing Tagger on Split ${i}`);
            }
        }

        if (required.length > 0) {
            this.setState({
                required: required,
            });
        } else {
            ajax_wrapper('POST', url, data, (value) => {
                if (add_songs) {
                    window.location.href = `/project/${value['id']}/add_songs/`;
                } else {
                    window.location.href = `/project/${value['id']}/0/`;
                }
            });
        }
    };

    add_split = () => {
        let available_tag_types = [];
        for (let tag_type of this.state.tag_type_options) {
            let found = false;
            for (let i in [...Array(this.state.split_count)]) {
                if (
                    this.state[`split_${parseInt(i) + 1}_tag_types`].includes(
                        tag_type.value,
                    )
                ) {
                    found = true;
                }
            }
            if (!found) {
                available_tag_types.push(tag_type.value);
            }
        }
        this.setState({
            split_count: this.state.split_count + 1,
            [`split_${this.state.split_count + 1}_tag_types`]:
                available_tag_types,
        });
    };

    remove_split = () => {
        this.clear_split_state(this.state.split_count);
        this.setState({ split_count: this.state.split_count - 1 });
    };

    clear_split_state = (i) => {
        this.setState({
            [`split_${i}_taggers`]: [],
            [`split_${i}_tag_types`]: [],
        });
    };

    save_client_default = () => {
        let url = '/api/save_client_default/';
        let data = {
            name: this.state.name,
            client_id: this.state.client_id,
            taxonomy_id: this.state.taxonomy_id,
            export_format: this.state.export_format,
            project_notes: this.state.project_notes,
            split_count: this.state.split_count,
        };

        let required = [];
        for (let key in data) {
            if (key in NEW_PROJECT_REQUIRED && !data[key]) {
                required.push(`Missing ${NEW_PROJECT_REQUIRED[key]}`);
            }
        }

        for (let i in [...Array(this.state.split_count).keys()]) {
            i = parseInt(i) + 1;

            if (this.state[`split_${i}_tag_types`]) {
                data[`split_${i}_tag_types`] =
                    this.state[`split_${i}_tag_types`];
            } else {
                data[`split_${i}_tag_types`] = [];
            }

            if (!this.state[`split_${i}_taggers`]) {
                required.push(`Missing Tagger on Split ${i}`);
            }
        }

        if (required.length > 0) {
            this.setState({
                required: required,
            });
        } else {
            this.setState({
                client_default_button_text: 'Saving...',
            });
            ajax_wrapper('POST', url, data, (value) => {
                if (value.success) {
                    this.setState({
                        client_default_button_text: 'Client Default Saved!',
                    });
                } else {
                    this.setState({
                        client_default_button_text: 'Error: Default not saved.',
                    });
                }
            });
        }
    };

    render() {
        let context = this.state.context;

        let export_formats = [];
        let client_options = [];
        let taxonomy_options = [];
        let tagger_options = [];

        for (let client of context.clients) {
            client_options.push({ text: client['name'], value: client.id });
        }

        client_options = sort_objects(client_options, ['text']);

        for (let taxonomy of context.taxonomies) {
            taxonomy_options.push({
                text: taxonomy['name'],
                value: taxonomy['id'],
            });

            if (this.state.taxonomy_id == taxonomy['id']) {
                for (let format of taxonomy['export_formats']) {
                    export_formats.push({
                        text: format['name'],
                        value: format['id'],
                    });
                }
            }
        }

        for (let tagger of this.state.context.taggers) {
            tagger_options.push({ text: tagger.name, value: tagger.id });
        }

        let split_width = '50%';

        if (this.state.split_count !== 1) {
            split_width = 100 / this.state.split_count + '%';
        }

        let split_cards = [];
        for (let i in [...Array(this.state.split_count).keys()]) {
            i = parseInt(i) + 1;
            split_cards.push(
                <td
                    width={split_width}
                    style={{ verticalAlign: 'top', paddingRight: '4px' }}
                >
                    <h3>Split {i}</h3>
                    <MultiSelect
                        style={{ fontStyle: 'bold' }}
                        name={`split_${i}_taggers`}
                        label="Taggers:"
                        options={tagger_options}
                        required={true}
                    />

                    <MultiSelect
                        multiple={true}
                        name={`split_${i}_tag_types`}
                        label="Fields:"
                        value={this.state[`split_${i}_tag_types`]}
                        item_pool={true}
                        select_all={this.state.select_all}
                        required={true}
                        options={this.state.tag_type_options}
                    />
                </td>,
            );
        }

        let required_jsx = [<br />];
        for (let item of this.state.required) {
            required_jsx.push(<Alert type="danger">{item}</Alert>);
        }

        return (
            <div key={this.state.timestamp}>
                <Form
                    set_global_state={(state_name, state) =>
                        this.setState(Object.assign({ required: [] }, state))
                    }
                    auto_set_global_state={true}
                >
                    <table>
                        <tr>
                            <td>
                                <h2>Create New Project</h2>
                            </td>
                            <td width="55%">
                                <TextInput
                                    name="name"
                                    className="new-project-name"
                                    style={{
                                        color: 'white',
                                        marginLeft: '10px',
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        fontSize: '24px',
                                        borderTop: '0px',
                                        borderRight: '0px',
                                        borderLeft: '0px',
                                        borderRadius: '0px',
                                    }}
                                    required={'name' in NEW_PROJECT_REQUIRED}
                                    placeholder="Project Name"
                                />
                            </td>
                            <td
                                style={{
                                    paddingLeft: '100px',
                                }}
                            ></td>
                        </tr>
                    </table>
                    <br />
                    <table
                        style={{
                            width: '100%',
                        }}
                    >
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>
                                <Select
                                    name="client_id"
                                    label="Client"
                                    options={client_options}
                                    required={
                                        'client_id' in NEW_PROJECT_REQUIRED
                                    }
                                />
                            </td>
                            <td
                                style={{
                                    width: '50%',
                                    paddingLeft: '20px',
                                }}
                            >
                                <TextArea
                                    name="project_notes"
                                    label="Project Notes:"
                                    required={
                                        'project_notes' in NEW_PROJECT_REQUIRED
                                    }
                                />
                            </td>
                        </tr>

                        <tr style={{ paddingTop: '50px' }}>
                            <td>
                                <Select
                                    name="taxonomy_id"
                                    label="Taxonomy"
                                    value={this.state.taxonomy_id}
                                    options={taxonomy_options}
                                    required={
                                        'taxonomy_id' in NEW_PROJECT_REQUIRED
                                    }
                                />
                            </td>
                            <td
                                style={{
                                    width: '50%',
                                    paddingLeft: '20px',
                                    paddingTop: '25px',
                                }}
                            >
                                <Select
                                    name="export_format"
                                    label="Export Format"
                                    value={this.state.export_format}
                                    options={export_formats}
                                    required={
                                        'export_format' in NEW_PROJECT_REQUIRED
                                    }
                                />
                                <a
                                    href="admin/exportformat/"
                                    style={{ textAlign: 'right' }}
                                >
                                    Add new Export Format...
                                </a>
                            </td>
                        </tr>
                    </table>
                    <br />

                    <div
                        style={{
                            display: this.state.splits_display
                                ? 'block'
                                : 'none',
                        }}
                    >
                        <table>
                            <tr>
                                {split_cards}
                                <td>
                                    <NewSplitCard
                                        new_split={this.add_split}
                                        remove_split={this.remove_split}
                                        split_count={this.state.split_count}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{ marginTop: '15px' }} />
                    <div>
                        <Button
                            type="success"
                            style={{ marginRight: '10px' }}
                            onClick={() => this.submit(true)}
                        >
                            Create and Add Songs
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => this.submit(false)}
                        >
                            Create Project
                        </Button>
                        <Button
                            type="warning"
                            style={{ float: 'right' }}
                            onClick={this.save_client_default}
                        >
                            {this.state.client_default_button_text}
                        </Button>
                    </div>
                    <div>{required_jsx}</div>
                </Form>
            </div>
        );
    }
}
