import React, { Component } from 'react';

import { ajax_wrapper, sort_objects } from 'functions';
import { Container, Loading } from 'library';

export default class SongsByClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: {},
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/songs_by_client/`, {}, (value) =>
            this.setState({
                data: value,
                loaded: true,
            }),
        );
    }

    render() {
        let clients = [];

        for (let key in this.state.data) {
            let song_by_month = [];

            let months_ordered = Object.keys(this.state.data[key]).sort();

            for (let month of months_ordered) {
                song_by_month.push(
                    <div className="row col-12">
                        <div className="col-3">{month}</div>
                        <div className="col-3">
                            {this.state.data[key][month]['all']}
                        </div>
                        <div className="col-3">
                            {this.state.data[key][month]['all'] -
                                this.state.data[key][month]['alts']}
                        </div>
                        <div className="col-3">
                            {this.state.data[key][month]['alts']}
                        </div>
                    </div>,
                );
            }

            clients.push(
                <div className="simple-card">
                    <h4>{key}</h4>
                    <div className="row">
                        <div
                            className="row col-12"
                            style={{ fontWeight: 'bold', marginBottom: '10px' }}
                        >
                            <div className="col-3">Month</div>
                            <div className="col-3">All Songs</div>
                            <div className="col-3">Mains</div>
                            <div className="col-3">Alts</div>
                        </div>
                        {song_by_month}
                    </div>
                </div>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container row">
                        <div className="simple-card">
                            <h2>Songs By Client By Month</h2>
                        </div>
                        {clients}
                    </div>
                </Loading>
            </Container>
        );
    }
}
