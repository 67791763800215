import React, { Component } from 'react';
import $ from 'jquery';

import {
    ajax_wrapper,
    update_history,
    get_global_state,
    sort_objects,
} from 'functions';
import { NewProject, ProjectCard } from 'components';
import { Container, Loading, Button, Modal, Select } from 'library';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Projects extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];

        this.state = {
            loaded: false,
            projects: [],
            clients: [],
            selected_client: params['client'] ? params['client'] : '',
            show_project_create: false,
            tagger: { name: '' },
        };
    }

    componentDidMount() {
        this.refresh_projects();

        window.addEventListener('popstate', this.handle_history_change);
    }

    refresh_projects = () => {
        ajax_wrapper('GET', `/api/my_projects/`, {}, (value) =>
            this.setState(
                {
                    loaded: true,
                    projects: value['projects'],
                    clients: value['clients'],
                    tagger: value['tagger'],
                    admin: value['admin'],
                },
                function () {
                    ajax_wrapper(
                        'GET',
                        `/api/my_project_summaries/`,
                        {},
                        this.load_project_summaries,
                    );
                }.bind(this),
            ),
        );
    };

    load_project_summaries = (value) => {
        for (let item of this.state.projects) {
            if (item['id'] in value['project_summaries']) {
                item = Object.assign(
                    item,
                    value['project_summaries'][item['id']],
                );
            }
        }

        this.setState({ projects: this.state.projects });
    };

    handle_history_change = (event) => {
        let params = get_global_state()['params'];

        if (params['client'] != this.state.selected_client) {
            this.setState({
                selected_client: params['client'],
            });
        }
    };

    render() {
        let projects = [];
        let completed_projects = [];

        let client_options = this.state.clients.map((client) => {
            return { text: client.name, value: client.id };
        });

        client_options = sort_objects(client_options, ['text']);

        let tagger_name = this.state.tagger['name'];
        let user = get_global_state()['user'];

        if (['owner', 'admin'].includes(user['role'])) {
            if (this.state.projects.length > 0) {
                let new_project_button = (
                    <div
                        className="simple-card project-card flex-container"
                        style={{ width: '80px' }}
                    >
                        <Button
                            type="primary"
                            onClick={() =>
                                this.setState({ show_project_create: true })
                            }
                            className="dark-button new-project-button"
                        >
                            +
                        </Button>
                    </div>
                );
                projects.push(new_project_button);
            }
        }

        for (let item of this.state.projects) {
            if (
                item.client == this.state.selected_client ||
                !this.state.selected_client
            ) {
                if (item.marked_complete) {
                    completed_projects.push(
                        <ProjectCard data={item} tagger={this.state.tagger} />,
                    );
                } else {
                    projects.push(
                        <ProjectCard data={item} tagger={this.state.tagger} />,
                    );
                }
            }
        }

        // TODO: change below card to not show for non-admins

        if (projects.length === 0) {
            let owner_options = null;
            if (['owner', 'admin'].includes(user['role'])) {
                owner_options = (
                    <div>
                        <i>{'—> Select from the following —> '}</i>
                        <br />
                        <br />
                        <Button
                            type="primary"
                            className="dark-button"
                            onClick={() =>
                                this.setState({ show_project_create: true })
                            }
                        >
                            Create a new project
                        </Button>
                        <br />
                    </div>
                );
            }
            projects.push(
                <div style={{ paddingBottom: '10px' }}>
                    <h3>You have no active projects!</h3>
                    {owner_options}
                </div>,
            );
        }

        let projects_jsx = [];

        if (user['role'] == 'tagger') {
            projects_jsx.push(
                <div id="tagger-projects">
                    <h1>Active Projects - {tagger_name}</h1>
                    <div className="simple-card-container row">{projects}</div>
                    <br />
                </div>,
            );
        } else {
            projects_jsx.push(
                <div>
                    <div
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'center',
                        }}
                    >
                        <h1
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'center',
                            }}
                        >
                            All Projects
                        </h1>
                        <Select
                            placeholder="Filter by Client"
                            name="selected_client"
                            className="transparent-select"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                            }}
                            options={client_options}
                            value={this.state.selected_client}
                            set_form_state={function (state) {
                                this.setState(state);
                                update_history(
                                    {},
                                    `/?client=${state['selected_client']}`,
                                );
                            }.bind(this)}
                        />
                    </div>

                    <div className="simple-card-container row">{projects}</div>
                    <br />
                    <br />
                    <div
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'center',
                        }}
                    >
                        <h1
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'center',
                            }}
                        >
                            Completed Projects
                        </h1>
                    </div>

                    <div className="simple-card-container row">
                        {completed_projects}
                    </div>
                    <br />
                    <br />
                </div>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <Modal
                            show={this.state.show_project_create}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_project_create: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        >
                            <div className="simple-card-container modal-bounding-box">
                                <div className="simple-card">
                                    <NewProject />
                                </div>
                            </div>
                        </Modal>

                        <div>{projects_jsx}</div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
