import React, { Component } from 'react';
import $ from 'jquery';

import {
    Form,
    TextInput,
    Container,
    Loading,
    Button,
    Modal,
    MultiSelect,
} from 'library';

import { NewTaxonomyTag } from 'components';

import { ajax_wrapper, get_global_state } from 'functions';

import { UploadPresets, AddTagTypes } from './import_presets';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.admin-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

class TagType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            add_tags: [],
            remove_tags: [],
            show_add_tag: false,
            selected_parent: null,
        };
    }

    show_hide = () => {
        if (this.state.show) {
            this.setState({ show: false, dropdown_button_rotation: 0 });
        } else {
            this.setState({ show: true, dropdown_button_rotation: 180 });
        }
    };

    submit_changes = () => {
        let url = `/api/modify_taxonomy/${this.props.data.taxonomy}/`;
        let data = {
            add_tags: this.state.add_tags,
            remove_tags: this.state.remove_tags,
        };

        ajax_wrapper('POST', url, data, (value) => {
            this.props.update_tags(value);
            this.setState({ add_tags: [], remove_tags: [] });
        });
    };

    show_add_tag_modal = (parent) => {
        this.setState({ show_add_tag: true, selected_parent: parent });
    };

    addTag = (data) => {
        let add_tags = [...this.state.add_tags];
        add_tags.push({
            tag_name: data.tag_name,
            parent: data.parent,
            tag_type: this.props.data['child_tagtype'],
            dropdown_category: this.state.selected_parent.dropdown_category,
            color_category: this.state.selected_parent.color_category,
        });

        this.setState({ add_tags: add_tags, show_add_tag: false });
    };

    removeTag = (tag) => {
        let remove_tags = [...this.state.remove_tags];

        remove_tags.push({
            id: tag['id'],
            tag_name: tag['name'],
        });

        this.setState({ remove_tags: remove_tags });
    };

    remove_edit_tag = (tag) => {
        let remove_tags = [...this.state.remove_tags];
        let add_tags = [...this.state.add_tags];

        for (let item of remove_tags) {
            if (item.id === tag.id) {
                let index = remove_tags.indexOf(item);
                remove_tags.splice(index, 1);
            }
        }

        for (let item of add_tags) {
            if (item.tag_name === tag.tag_name) {
                let index = add_tags.indexOf(item);
                add_tags.splice(index, 1);
            }
        }

        this.setState({ add_tags: add_tags, remove_tags: remove_tags });
    };

    clearEditTag = (tag) => {
        let remove_tags = [...this.state.remove_tags];
        let add_tags = [...this.state.add_tags];
    };

    render() {
        let dropdown_arrow = (
            <img
                class="clear-text-button"
                src="/static/images/dropdown-button-white.png"
                style={{
                    height: '8px',
                    opacity: '80%',
                    float: 'right',
                    marginTop: '13px',
                    marginLeft: '10px',
                    marginRight: '5px',
                    rotate: this.state.dropdown_button_rotation + 'deg',
                }}
            />
        );

        let edit_tag_buttons = [];

        for (let tag of this.state.remove_tags) {
            edit_tag_buttons.push(
                <Button
                    className="taxonomy-edit-tag-remove"
                    onClick={() => this.remove_edit_tag(tag)}
                >
                    {tag['tag_name']}
                </Button>,
            );
        }

        for (let tag of this.state.add_tags) {
            edit_tag_buttons.push(
                <Button
                    className="taxonomy-edit-tag-add"
                    onClick={() => this.remove_edit_tag(tag)}
                >
                    {tag['tag_name']}
                </Button>,
            );
        }

        let tags = [];
        let remove_tags_ids = [];
        for (let rm_tag of this.state.remove_tags) {
            remove_tags_ids.push(rm_tag['id']);
        }

        for (let item of this.props.data['tags']) {
            tags.push(<h4 className="taxonomy-pool-header">{item['name']}</h4>);

            for (let child of item['children']) {
                if (!remove_tags_ids.includes(child['id'])) {
                    tags.push(
                        <Button
                            className="taxonomy-pool-tag"
                            onClick={() => this.removeTag(child)}
                        >
                            {child['name']}
                        </Button>,
                    );
                }
            }

            tags.push(
                <Button
                    className="taxonomy-pool-tag"
                    onClick={() => this.show_add_tag_modal(item)}
                >
                    +
                </Button>,
            );

            tags.push(<hr />);
        }

        let apply_changes_button = '';

        if (this.state.add_tags.length + this.state.remove_tags.length !== 0) {
            apply_changes_button = (
                <Button
                    type="primary"
                    style={{
                        marginLeft: '10px',
                        marginBottom: '10px',
                    }}
                    onClick={this.submit_changes}
                >
                    Apply Changes
                </Button>
            );
        }

        return (
            <div className="simple-card">
                <NewTaxonomyTag
                    tag_type={this.props.data.name}
                    tag_type_id={this.props.data.id}
                    taxonomy={this.props}
                    parent={this.state.selected_parent}
                    parents={this.props.data.tags}
                    show={this.state.show_add_tag}
                    add_tag={this.addTag}
                    on_hide={() =>
                        this.setState(
                            {
                                show_add_tag: false,
                            },
                            focus_on_page,
                        )
                    }
                />
                <div onClick={this.show_hide}>
                    <h5 style={{ display: 'inline-block', width: '20%' }}>
                        {this.props.data['name']}
                    </h5>
                    <p
                        style={{
                            display: 'inline-block',
                            color: 'rgba(221, 47, 47, 1)',
                            fontStyle: 'italic',
                        }}
                    >
                        {this.props.data['required'] ? 'Required' : ''}
                    </p>
                    {dropdown_arrow}
                </div>
                <div style={{ display: this.state.show ? 'block' : 'none' }}>
                    <table>
                        <tr style={{ paddingBottom: '0px' }}>
                            <td
                                style={{
                                    paddingRight: '20px',
                                    height: '12px',
                                }}
                            >
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    Minimum tags:
                                </h6>
                            </td>
                            <td>
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    {this.props.data['min']}
                                </h6>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    Maximum tags:
                                </h6>
                            </td>
                            <td style={{ fontWeight: 'lighter' }}>
                                {this.props.data['max']}
                            </td>
                        </tr>
                    </table>

                    <div className={'taxonomy-edit-tag-pool'}>
                        {edit_tag_buttons}
                    </div>
                    {apply_changes_button}

                    <div>{tags}</div>
                </div>
            </div>
        );
    }
}

class Preset extends Component {
    render() {
        let presets_by_tagtype = {};
        let preset_tags = [];
        for (let child of this.props.data['preset_tags']) {
            if (!Object.keys(presets_by_tagtype).includes(child.tag_type)) {
                presets_by_tagtype[child.tag_type] = [];
            }
            presets_by_tagtype[child.tag_type].push(
                <div className="badge text-bg-info">{child.name}</div>,
            );
        }

        let color_increment = 50;
        for (let tag_type of Object.keys(presets_by_tagtype)) {
            preset_tags.push(
                <div>
                    <div
                        style={{
                            backgroundColor: `rgba(120, ${color_increment}, 40, .6)`,
                            width: '95%',
                            paddingLeft: '5px',
                            marginBottom: '3px',
                            borderRadius: '4px',
                        }}
                    >
                        {tag_type}
                    </div>
                    {presets_by_tagtype[tag_type]}
                </div>,
            );
            color_increment = color_increment + 40;
        }

        return (
            <div className="simple-card">
                <h4>{this.props.data['name']}</h4>
                {preset_tags}
                <br />
            </div>
        );
    }
}

export default class ManageTaxonomy extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = `/api/home/taxonomy/${params['id']}/`;

        this.state = {
            url: url,
            loaded: false,
            data: null,
            tags: [],
            presets: [],
            upload_presets: false,
            add_tagtypes: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', this.state.url, {}, (value) => {
            let clients = [];
            for (let item of value['clients']) {
                clients.push(item['id']);
            }
            value['clients'] = clients;

            this.setState({ loaded: true, data: value });
        });

        if (params['id']) {
            ajax_wrapper(
                'GET',
                `/api/taxonomy_tags/${params['id']}/`,
                {},
                (value) => this.setState({ loaded: true, tags: value }),
            );
            ajax_wrapper(
                'GET',
                `/api/taxonomy_presets/${params['id']}/`,
                {},
                (value) => this.setState({ loaded: true, presets: value }),
            );
        }
    }

    update_tags = (value) => {
        this.setState({ tags: value });
    };

    render() {
        let user = get_global_state()['user'];
        let params = get_global_state()['params'];

        let tag_types = [];
        for (let item of this.state.tags) {
            tag_types.push(
                <TagType
                    data={item}
                    addTag={this.addTag}
                    update_tags={this.update_tags}
                />,
            );
        }

        let presets = [];
        for (let item of this.state.presets.sort((a, b) =>
            a.name.localeCompare(b.name),
        )) {
            presets.push(<Preset data={item} />);
        }

        let upload_presets = (
            <Modal
                show={this.state.upload_presets}
                on_hide={() => this.setState({ upload_presets: false })}
            >
                <div className="simple-card">
                    <UploadPresets taxonomy_id={params['id']} />
                </div>
            </Modal>
        );

        let add_tagtypes = (
            <Modal
                show={this.state.add_tagtypes}
                on_hide={() => this.setState({ add_tagtypes: false })}
            >
                <div className="simple-card">
                    <AddTagTypes taxonomy_id={params['id']} />
                </div>
            </Modal>
        );

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <h1>Edit Taxonomy</h1>
                            <Form
                                defaults={this.state.data}
                                submit_url={this.state.url}
                                delete_url={this.state.url}
                                redirect={function () {
                                    window.location.href =
                                        '/admin/home/taxonomy/';
                                }}
                            >
                                <TextInput name="name" label="name" />
                                {user['role'] == 'admin' ? (
                                    <MultiSelect
                                        name="clients"
                                        label="Clients"
                                        options_url="/api/home/client/"
                                    />
                                ) : (
                                    <MultiSelect
                                        name="clients"
                                        label="Clients"
                                        options_url={`/api/home/client/?owners=${user['id']}`}
                                    />
                                )}

                                <br />
                            </Form>
                        </div>

                        <div className="simple-card">
                            {params['id'] ? (
                                <div>
                                    <Button
                                        type="primary"
                                        href={`/admin/exportformat/?taxonomy=${params['id']}`}
                                    >
                                        Add Export Format
                                    </Button>
                                    <Button
                                        type="primary"
                                        href={`/admin/uilayout/?taxonomy=${params['id']}`}
                                    >
                                        Add UI Layout
                                    </Button>
                                    <Button
                                        type="primary"
                                        href={`/admin/mlmodel/?taxonomy=${params['id']}`}
                                    >
                                        Add ML Model
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.setState({
                                                upload_presets: true,
                                            })
                                        }
                                    >
                                        Upload Presets
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.setState({
                                                add_tagtypes: true,
                                            })
                                        }
                                    >
                                        Add TagTypes
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <div className="simple-card-container">{tag_types}</div>
                    </div>
                    <div>
                        <h4>Presets</h4>
                        <div className="simple-card-container">{presets}</div>
                    </div>

                    {upload_presets}
                    {add_tagtypes}
                </Loading>
            </Container>
        );
    }
}
