import React, { Component } from 'react';
import $ from 'jquery';

import {
    Alert,
    Container,
    Loading,
    Link,
    Button,
    AudioPlayer,
    TextArea,
    Image,
    Accordion,
    MultiSelect,
} from 'library';
import {
    ajax_wrapper,
    get_global_state,
    sort_objects,
    toast_handler,
} from 'functions';
import {
    TagButton,
    DynamicSearch,
    BPM,
    KeyFinder,
    SentenceOptions,
    SongListItem,
} from 'components';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

class TagType extends Component {
    render() {
        let type = this.props.type;
        let current_tags_of_type = this.props.current_tags_of_type;

        let tags = [];
        let custom_jsx_field = [];

        for (let tag_type_data of this.props.tag_types) {
            if (
                type['id'] === tag_type_data.id &&
                tag_type_data.name === 'SoundAlikes' &&
                this.props.special_fields.includes('custom_soundalikes')
            ) {
                custom_jsx_field = (
                    <div>
                        <h6>Write-in:</h6>
                        <TextArea
                            style={{ fontSize: '18px' }}
                            value={this.props.data['custom_soundalikes']}
                            handle_change={(event) =>
                                this.props.update_details({
                                    custom_soundalikes:
                                        event.currentTarget.value,
                                })
                            }
                        />
                    </div>
                );
                break;
            }
        }

        // Special ordering for Subgenre tags
        if (type['name'] == 'Subgenres') {
            sort_objects(current_tags_of_type, ['added_to_song']);
        }

        for (let tag of current_tags_of_type) {
            tags.push(
                <TagButton
                    data={tag}
                    active={'active'}
                    toggle_tag={this.props.toggle_tag}
                />,
            );
        }

        return (
            <div className="col-4">
                <div className="simple-card">
                    <Button
                        className="tagtype-header-button"
                        type="primary"
                        onClick={() =>
                            this.props.open_search(type['name'], type['id'])
                        }
                    >
                        {type['name']}
                    </Button>
                    {custom_jsx_field}
                    {tags}
                </div>
            </div>
        );
    }
}
//
export default class Song extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags_loaded: false,
            songs_loaded: false,
            data: {
                tags: [],
                tag_types: [],
            },
            project_data: {},
            tracks_remaining: 0,
            dropdown_categories: {},
            show_search: false,
            search_type_name: '',
            search_type: '',
            tag_types: [],
            special_fields: [],
            tag_type_lookup: {},
            tag_lookup: {},
            tag_type_reverse_lookup: {},
            recent_songs: [],
            sentences: {},
            loading_sentences: false,
            check_genres: false,
            custom_instruments: '',
            custom_soundalikes: '',

            required: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        if (!('split' in params)) {
            params['split'] = 0;
        }

        ajax_wrapper('GET', `/api/song_details/${params['id']}/`, {}, (value) =>
            this.setState(
                { data: value },
                function () {
                    ajax_wrapper(
                        'GET',
                        `/api/project_songs_details/${this.state.data['project']}/`,
                        {},
                        (value) => {
                            let tracks_remaining = this.get_tracks_remaining(
                                value,
                                params['split'],
                            );

                            this.setState(
                                {
                                    project_songs: value,
                                    tracks_remaining: tracks_remaining,
                                },
                                this.load_recent_songs,
                            );
                        },
                    );

                    ajax_wrapper(
                        'GET',
                        `/api/project_details/${this.state.data['project']}/${params['split']}/`,
                        {},
                        (value) => {
                            this.load_tags(
                                value['taxonomy_tags'],
                                value['tag_types'],
                            );
                            this.setState({
                                project_data: value,
                                dropdown_categories:
                                    value['dropdown_categories'],
                                special_fields: value['special_fields'],
                                split_view: value['split_view'],
                            });
                        },
                    );
                }.bind(this),
            ),
        );

        focus_on_page();
    }

    get_tracks_remaining = (songs, split) => {
        let tracks_complete = 0;

        for (let song of songs) {
            let status = song.status_by_split[split]['status'];
            if (status === 'Complete') {
                tracks_complete++;
            }
        }

        return songs.length - tracks_complete;
    };

    handle_key_press = (event) => {
        if (
            ((event.ctrlKey || event.metaKey) && event.key == 'f') ||
            (event.altKey && event.metaKey && event.key == ' ')
        ) {
            event.cancelBubble = true;
            event.preventDefault();
            this.open_search('', '');
        }
    };

    load_tags = (value, split_tag_types) => {
        let tag_type_lookup = {};
        let tag_lookup = {};
        let tag_types = [];

        let tag_type_ids = [];
        for (let tagtype of split_tag_types) {
            tag_type_ids.push(tagtype.id);
            tag_type_lookup[tagtype.id] = tagtype;
        }

        for (let tagtype of value) {
            if (tag_type_ids.includes(tagtype.id)) {
                tag_types.push(tagtype);

                for (let item of tagtype['tags']) {
                    tag_lookup[item['id']] = item;
                    for (let child of item['children']) {
                        tag_lookup[child['id']] = child;
                    }
                }
            }
        }

        this.setState({
            tags_loaded: true,
            tag_types: tag_types,
            tag_type_lookup: tag_type_lookup,
            tag_lookup: tag_lookup,
        });
    };

    load_recent_songs() {
        let params = get_global_state()['params'];
        if (!('split' in params)) {
            params['split'] = 0;
        }

        let recent_songs = [];
        let next_song = null;

        let sorted_songs = [...this.state.project_songs];
        sort_objects(sorted_songs, ['updated_at']);
        sorted_songs.reverse();

        for (let item of sorted_songs) {
            // Show any song that has been edited
            if (
                ['In Progress', 'Complete'].includes(
                    item['status_by_split'][params['split']]['status'],
                )
            ) {
                recent_songs.push(item);
            }

            //  DISABLED FOR NOW

            // if (recent_songs.length >= 50) {
            //     break;
            // }
        }

        for (let item of this.state.project_songs) {
            //if (item.order <= this.state.data['order']) {
            //    continue;
            //}

            if (item.id == this.state.data['id']) {
                continue;
            }

            if (
                item['status_by_split'][params['split']]['status'] == 'Complete'
            ) {
                continue;
            }

            next_song = item['id'];
            break;
        }

        this.setState({
            next_song: next_song,
            recent_songs: recent_songs,
            songs_loaded: true,
        });
    }

    open_search = (name, id) => {
        this.setState({
            show_search: true,
            search_type: name,
            search_type_id: id,
        });
    };

    toggle_tag = (id) => {
        let params = get_global_state()['params'];

        let data = {
            add: [],
            remove: [],
        };

        let tag = this.state.tag_lookup[id];
        let tag_type = null;

        for (let type of this.state.tag_types) {
            if (type.id === tag['tag_type']) {
                tag_type = type;
                break;
            }
        }

        let found_tag = null;
        let current_tags_of_type = [];

        for (let item of this.state.data['tags']) {
            if (item['id'] == id) {
                found_tag = this.state.data['tags'].indexOf(item);
            }
            if (item['tag_type'] === tag_type.id) {
                current_tags_of_type.push(item);
            }
        }
        if (found_tag == null) {
            if (current_tags_of_type.length < tag_type['max']) {
                data['add'].push(id);
                this.state.data['tags'].push(this.state.tag_lookup[id]);
            }
        } else {
            data['remove'].push(id);
            this.state.data['tags'].splice(found_tag, 1);
        }

        sort_objects(this.state.data['tags'], ['name']);

        this.setState(
            {
                data: this.state.data,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${params['id']}/`,
                    data,
                    (value) => {
                        let new_data = this.state.data;
                        for (let tag_id of value['triggered_tags']) {
                            new_data['tags'].push(
                                this.state.tag_lookup[tag_id],
                            );
                        }

                        new_data['status_by_split'] = value['status_by_split'];

                        this.setState({ data: new_data });
                    },
                ),
        );
    };

    clear_song_tags = () => {
        let params = get_global_state()['params'];
        let data = { ...this.state.data };
        data['tags'] = [];
        this.setState({ data: data }, () =>
            ajax_wrapper(
                'POST',
                `/api/clear_song_tags/${params['id']}/`,
                {},
                (value) => {},
            ),
        );
    };

    update_details = (value) => {
        // Will update all keys provided
        let new_data = Object.assign(this.state.data, value);
        this.setState({
            data: new_data,
            tags_loaded: true,
        });
    };

    save_details = (next_song) => {
        let params = get_global_state()['params'];
        let required = [];

        let data = {};
        for (let field of this.state.special_fields) {
            data[field] = this.state.data[field];
        }

        data['major_minor'] = this.state.data['major_minor'];

        if (next_song) {
            for (let item of this.state.data['status_by_split'][
                params['split']
            ]['invalid_types']) {
                if (Object.keys(this.state.tag_type_lookup).includes(item)) {
                    required.push(
                        `Missing ${this.state.tag_type_lookup[item]['name']}`,
                    );
                } else if (Object.keys(data).includes(item) && !data[item]) {
                    required.push(`Missing ${item}`);
                }
            }
        }

        if (required.length > 0) {
            this.setState({ required: required });
        } else {
            ajax_wrapper(
                'POST',
                `/api/home/song/${params['id']}/`,
                data,
                function () {
                    if (next_song && this.state.next_song) {
                        window.location = `/song/${this.state.next_song}/${params['split']}/`;
                    } else {
                        window.location = `/project/${this.state.data['project']}/${params['split']}/`;
                    }
                }.bind(this),
            );
        }
    };

    update_presets = (id) => {
        this.setState(
            { tags_loaded: false },
            ajax_wrapper(
                'POST',
                `/api/update_presets/${this.state.data['id']}/`,
                {},
                (value) => {
                    this.update_details({
                        tags: value['tags'],
                        status_by_split: value['status_by_split'],
                    });
                },
            ),
        );
    };

    get_sentences = () => {
        this.setState({ loading_sentences: true }, () =>
            ajax_wrapper(
                'GET',
                `/api/get_sentences/${this.state.data['id']}/`,
                {},
                (value) =>
                    this.setState({
                        sentences: value,
                        loading_sentences: false,
                    }),
            ),
        );
    };

    getGPTSentence = () => {
        ajax_wrapper(
            'GET',
            `/api/get_GPT_sentence/${this.state.data['id']}/`,
            {},
            (value) => this.update_details({ description: value['sentence'] }),
        );
    };

    copy_tags = (id) => {
        this.setState(
            { tags_loaded: false },
            ajax_wrapper(
                'POST',
                `/api/copy_tags/${this.state.data['id']}/${id}/`,
                {},
                (value) => this.update_details(value),
            ),
        );
    };

    alt_mix = (id) => {
        this.setState({ tags_loaded: false }, () =>
            ajax_wrapper(
                'POST',
                `/api/copy_tags/${this.state.data['id']}/${id}/`,
                { parent_mix: true },
                (value) => this.update_details(value),
            ),
        );
    };

    render() {
        let params = get_global_state()['params'];

        let current_tag_types = {};
        let tag_types_lookup = {};
        for (let tag_type of this.state.tag_types) {
            current_tag_types[tag_type['id']] = [];
            tag_types_lookup[tag_type['id']] = tag_type;
        }

        let song_tags = [];
        for (let item of this.state.data['tags']) {
            song_tags.push(item['id']);

            if (!(item['tag_type'] in current_tag_types)) {
                continue;
            }
            current_tag_types[item['tag_type']].push(item);
        }

        let current_tags = [];
        for (let type in current_tag_types) {
            current_tags.push(
                <TagType
                    // Data
                    data={this.state.data}
                    type={tag_types_lookup[type]}
                    current_tags_of_type={[...current_tag_types[type]]}
                    tag_types={this.state.tag_types}
                    special_fields={this.state.special_fields}
                    // Functions
                    update_details={this.update_details}
                    toggle_tag={this.toggle_tag}
                    open_search={this.open_search}
                />,
            );
        }

        let recent_songs = [];
        for (let item of this.state.recent_songs) {
            if (item['id'] == this.state.data['id']) {
                continue;
            }

            recent_songs.push(
                <div className="col-12">
                    <SongListItem
                        tag_types={this.state.tag_types}
                        data={item}
                        parent_song={this.state.data['song_parent']}
                        onClick={() => this.copy_tags(item['id'])}
                    >
                        <div style={{ marginTop: '4px', float: 'right' }}>
                            <Button
                                type="primary"
                                onClick={function (event) {
                                    event.stopPropagation();
                                    this.alt_mix(item['id']);
                                }.bind(this)}
                            >
                                Alt Mix
                            </Button>
                        </div>
                    </SongListItem>
                </div>,
            );
        }

        let bpm_special_field = [];
        let keyfinder_special_field = [];
        let description_special_field = [];
        let check_genres_special_field = [];
        let custom_instruments_special_field = [];

        if (this.state.special_fields.includes('bpm')) {
            bpm_special_field.push(
                <BPM
                    value={this.state.data['bpm']}
                    update={this.update_details}
                />,
            );
        }
        if (this.state.special_fields.includes('key')) {
            keyfinder_special_field.push(
                <KeyFinder
                    value={this.state.data['key']}
                    major_minor={this.state.data['major_minor']}
                    update={this.update_details}
                />,
            );
        }

        if (this.state.special_fields.includes('description')) {
            description_special_field.push(
                <Loading loaded={!this.state.loading_sentences} cover={true}>
                    <h4>Current Description:</h4>
                    <TextArea
                        style={{ fontSize: '18px' }}
                        value={this.state.data['description']}
                        handle_change={(event) =>
                            this.update_details({
                                description: event.currentTarget.value,
                            })
                        }
                    />
                    <SentenceOptions
                        data={this.state.sentences}
                        update={(value) => this.update_details(value)}
                    />
                    <br />
                    <row>
                        <Button type="danger" onClick={this.get_sentences}>
                            Generate Sentences!
                        </Button>
                        <Button
                            type="info"
                            style={{
                                backgroundColor: '#74aa9c',
                                borderColor: '#74aa9c',
                            }}
                            onClick={this.getGPTSentence}
                        >
                            Generate{' '}
                            <Image
                                src="/static/images/openai.png"
                                style={{ maxHeight: '16px' }}
                            />{' '}
                            GPT-4o Sentence!
                        </Button>
                    </row>
                </Loading>,
            );
        }

        if (this.state.special_fields.includes('check_genres')) {
            check_genres_special_field.push(
                <div style={{ display: 'inline-block', marginLeft: '8px' }}>
                    <h5 style={{ display: 'inline-block' }}>Check genres?</h5>
                    <input
                        type="checkbox"
                        style={{ transform: 'scale(1.3)', margin: '5px' }}
                        value={this.state.data['check_genres']}
                        onClick={(e) =>
                            this.update_details({
                                check_genres: e.target.checked,
                            })
                        }
                    />
                </div>,
            );
        }

        if (this.state.special_fields.includes('custom_instruments')) {
            custom_instruments_special_field.push(
                <div>
                    <h4>Custom Instruments:</h4>
                    <TextArea
                        style={{ fontSize: '18px' }}
                        value={this.state.data['custom_instruments']}
                        handle_change={(event) =>
                            this.update_details({
                                custom_instruments: event.currentTarget.value,
                            })
                        }
                    />
                </div>,
            );
        }

        let track_notes = [];
        if (this.state.data.track_notes) {
            for (let line of this.state.data.track_notes.split('<br>')) {
                track_notes.push(
                    <div>
                        {line}
                        <br />
                    </div>,
                );
            }
        }

        let required_jsx = [];
        for (let item of this.state.required) {
            required_jsx.push(<Alert type="danger">{item}</Alert>);
        }

        return (
            <div
                class="song-page-container"
                onKeyDown={this.handle_key_press}
                tabIndex="0"
            >
                <Container>
                    <Loading
                        loaded={
                            this.state.tags_loaded && this.state.songs_loaded
                        }
                        cover={true}
                    >
                        <DynamicSearch
                            show={this.state.show_search}
                            tag_type_name={this.state.search_type}
                            tag_type={this.state.search_type_id}
                            data={this.state.data}
                            project_view={false}
                            tag_types={this.state.tag_types}
                            tag_lookup={this.state.tag_lookup}
                            dropdown_categories={this.state.dropdown_categories}
                            toggle_tag={this.toggle_tag}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_search: false,
                                        search_type: '',
                                        search_type_id: '',
                                    },
                                    focus_on_page,
                                )
                            }
                        />
                        <div className="simple-card-container">
                            <div className="simple-card">
                                <Link
                                    href={`/project/${this.state.data['project']}/${params['split']}/`}
                                >
                                    Back to project
                                </Link>
                            </div>
                            <div className="simple-card">
                                <AudioPlayer
                                    playing={true}
                                    src={this.state.data['dropbox_url']}
                                    style={{ float: 'right' }}
                                    loop={true}
                                />
                                <h1>{this.state.data.name}</h1>
                                <div
                                    className="split-display-flag"
                                    style={{ display: 'inline-block' }}
                                >
                                    {this.state.split_view == 'Main'
                                        ? ''
                                        : this.state.split_view}
                                </div>
                                <a
                                    className="song-project-header"
                                    style={{ display: 'inline-block' }}
                                    href={`/project/${this.state.data['project']}/${params['split']}/`}
                                >
                                    Project:{' '}
                                    <span
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {' '}
                                        {this.state.project_data
                                            ? this.state.project_data['name']
                                            : ''}
                                    </span>
                                </a>
                                <div
                                    className="tracks-remaining-header"
                                    style={{ display: 'inline-block' }}
                                >
                                    {this.state.tracks_remaining} tracks
                                    remaining
                                </div>
                            </div>
                        </div>

                        <div
                            className="simple-card-container row"
                            style={{ marginLeft: '0px', marginRight: '0px' }}
                        >
                            <div className="col-12">
                                <div
                                    className="simple-card"
                                    style={{
                                        marginLeft: '0px',
                                        marginRight: '0px',
                                    }}
                                >
                                    <h4>Notes:</h4>
                                    <div>
                                        <p>{track_notes}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div
                                    className="simple-card"
                                    style={{ minHeight: '420px' }}
                                >
                                    <div>
                                        <h3>Recent Songs</h3>
                                        <div
                                            className="simple-card-container row"
                                            style={{
                                                margin: '0px',
                                                height: '357px',
                                                overflow: 'auto',
                                                alignContent: 'baseline',
                                            }}
                                        >
                                            {recent_songs}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-3"
                                style={{
                                    display: this.state.special_fields.includes(
                                        'bpm',
                                    )
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <div
                                    className="simple-card"
                                    style={{ minHeight: '420px' }}
                                >
                                    {bpm_special_field}
                                </div>
                            </div>
                            <div className="col-3">
                                <div
                                    className="simple-card"
                                    style={{
                                        minHeight: '420px',
                                        display:
                                            this.state.special_fields.includes(
                                                'key',
                                            )
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    {keyfinder_special_field}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="simple-card">
                                    <Button
                                        style={{ float: 'right' }}
                                        type="info"
                                        onClick={this.update_presets}
                                    >
                                        Get Presets
                                    </Button>
                                    <Button
                                        style={{ float: 'right' }}
                                        type="warning"
                                        onClick={this.clear_song_tags}
                                    >
                                        Clear All
                                    </Button>
                                    <h4>{'Current Tags'}</h4>
                                </div>
                            </div>

                            {current_tags}

                            <div className="col-12">
                                <div className="simple-card">
                                    <Button
                                        type="primary"
                                        onClick={() => this.open_search('', '')}
                                    >
                                        Search All Tags
                                    </Button>
                                </div>
                            </div>

                            <div className="col-12">
                                <div
                                    className="simple-card"
                                    style={{
                                        position: 'relative',
                                        display:
                                            this.state.special_fields.includes(
                                                'description',
                                            )
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    {custom_instruments_special_field}
                                    {description_special_field}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="simple-card">
                                    <div
                                        style={{ margin: '10px 0px 20px 0px' }}
                                    >
                                        <Button
                                            type="success"
                                            onClick={() =>
                                                this.save_details(true)
                                            }
                                            style={{ marginRight: '10px' }}
                                        >
                                            Save & Next Song
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => this.save_details()}
                                        >
                                            Save & Return to Project
                                        </Button>
                                    </div>
                                    {check_genres_special_field}

                                    {required_jsx}
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </Loading>
                </Container>
            </div>
        );
    }
}
