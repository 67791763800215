import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.playing !== this.props.playing && !this.props.playing) {
            if (this.audioRef.current) {
                this.audioRef.current.audioEl.current.pause();
            }
        }
    }
    render() {
        return (
            <ReactAudioPlayer
                ref={this.audioRef}
                className={this.props.className}
                style={this.props.style}
                src={this.props.src}
                controls
                loop={this.props.loop}
                preload={this.props.preload}
                onPlay={this.props.onPlayStateChange}
            />
        );
    }
}

export default AudioPlayer;
