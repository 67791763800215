import React, { Component } from 'react';
import $ from 'jquery';
import { Form, TextInput, Container, Loading, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

import { NewMLModal, MLModelCard } from 'components';

import { ObjectList } from './object_list';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.admin-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class MLManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            show_new_ml: false,
            models: [],
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/get_ml_models/`, {}, (value) =>
            this.setState({
                loaded: true,
                models: value['ml_models'],
                taxonomy_options: value['taxonomy_options'],
                ml_tagtype_options: value['tagtype_options'],
            }),
        );

        focus_on_page();
    }

    newModelCallback = (value) => this.setState({ models: value['ml_models'] });

    deleteModel = (model_id) => {
        ajax_wrapper('POST', `/api/delete_ml_model/${model_id}/`, {}, (value) =>
            this.setState({ models: value['ml_models'] }),
        );
    };

    render() {
        const ml_threshold_options = Array.from(
            { length: 20 },
            (_, i) => Math.round(i * 5) / 100,
        );

        let model_cards = [];

        console.log(this.state.models);

        for (let model of this.state.models) {
            model_cards.push(
                <MLModelCard data={model} deleteSelf={this.deleteModel} />,
            );
        }

        return (
            <div class="admin-page-container">
                <Container>
                    <Loading loaded={this.state.loaded}>
                        <NewMLModal
                            data={{ users: this.state.users }}
                            ml_model_options={this.state.ml_tagtype_options}
                            threshold_options={ml_threshold_options}
                            taxonomy_options={this.state.taxonomy_options}
                            show={this.state.show_new_ml}
                            callback={this.newModelCallback}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_new_ml: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        />
                        <div>
                            <h1>ML Models:</h1>
                            <Button
                                type="primary"
                                style={{ display: 'inline-block' }}
                                onClick={() =>
                                    this.setState({ show_new_ml: true })
                                }
                            >
                                New ML Model
                            </Button>
                            <div className="simple-card-container row">
                                {model_cards}
                            </div>
                        </div>
                    </Loading>
                </Container>
            </div>
        );
    }
}
