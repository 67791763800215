import React, { Component } from 'react';

export default class DropdownArrow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let rotation = this.props.show ? 0 : 180;
        return (
            <div
                onClick={this.props.onClick}
                style={{ padding: '13px 5px 0px 10px' }}
            >
                <img
                    class="clear-text-button"
                    src="/static/images/dropdown-button-white.png"
                    style={{
                        height: '10px',
                        opacity: '80%',
                        verticalAlign: 'middle',
                        rotate: rotation + 'deg',
                    }}
                />
            </div>
        );
    }
}
