import React, { Component } from 'react';

import { Button, Image, Loading } from 'library';
import { export_project, get_global_state } from 'functions';

export default class ProjectCard extends Component {
    render() {
        let user = get_global_state()['user'];
        let has_general_permission = ['owner', 'admin'].includes(user['role']);
        let project_data = this.props.data;

        let view_button = null;
        let split_jsx = [];
        let tagger_in_all_splits = true;
        if (project_data['splits']) {
            for (let split of project_data['splits']) {
                let tagger_in_split = false;
                for (let tagger of split.taggers) {
                    if (tagger.id === this.props.tagger.id) {
                        tagger_in_split = true;
                        break;
                    }
                }
                if (!tagger_in_split) {
                    tagger_in_all_splits = false;
                }

                let has_edit_permission =
                    tagger_in_split || has_general_permission;

                if (project_data['splits'].length > 1) {
                    if (has_edit_permission) {
                        split_jsx.push(
                            <Button
                                className="split-display-flag"
                                href={`/project/${project_data['id']}/${split['split_number']}/`}
                            >
                                Split {split['split_number']}
                            </Button>,
                        );
                    }
                }

                if (has_general_permission) {
                    for (let tagger of split['taggers']) {
                        split_jsx.push(
                            <span className="multiselect-selection">
                                {tagger.name}
                            </span>,
                        );
                    }
                    split_jsx.push(<br />);
                }
            }

            if (tagger_in_all_splits || has_general_permission) {
                view_button = (
                    <Button
                        type="primary"
                        style={{ marginBottom: '10px' }}
                        href={`/project/${project_data['id']}/${0}/`}
                    >
                        View
                    </Button>
                );
            }
        }

        let split_status_jsx = [
            <div>
                <div
                    className="project-card-text"
                    style={{ fontWeight: 'bold' }}
                >
                    Total Tracks: <Loading inline={true} loaded={false} />
                    <br />
                </div>
                <div className="project-card-text">
                    Tracks Complete: <Loading inline={true} loaded={false} />
                    <br />
                    Tracks Remaining: <Loading inline={true} loaded={false} />
                </div>
            </div>,
        ];

        if (project_data['split_songs_summary']) {
            split_status_jsx = [];
            split_status_jsx.push(
                <div
                    className="project-card-text"
                    style={{ fontWeight: 'bold' }}
                >
                    Total Tracks:{' '}
                    {project_data['split_songs_summary'][0]['total']}
                    <br />
                </div>,
            );

            for (let split of project_data['splits']) {
                let split_number = split['split_number'];
                let split_summary =
                    project_data['split_songs_summary'][split_number];

                if (project_data['splits'].length === 1) {
                    split_status_jsx.push(
                        <div className="project-card-text">
                            Tracks Complete: {split_summary['complete']}
                            <br />
                            Tracks Remaining:{' '}
                            {split_summary['total'] - split_summary['complete']}
                        </div>,
                    );
                } else {
                    split_status_jsx.push(
                        <div className="project-card-text">
                            Split {split_number} Tracks remaining:{' '}
                            {split_summary['total'] - split_summary['complete']}
                        </div>,
                    );
                }
            }
        }

        return (
            <div className="col-3">
                <div className="simple-card project-card ">
                    <h4>{project_data['name']}</h4>
                    <p className="project-card-subtitle">
                        Client: {project_data['client_name']}
                    </p>

                    {split_jsx}
                    {split_status_jsx}
                    <br />

                    <div>
                        {view_button}

                        {has_general_permission ? (
                            <Image
                                src="/static/images/download-icon-medium-white.png"
                                className="project-download-button"
                                onClick={() => {
                                    export_project(
                                        project_data['id'],
                                        project_data['export_format'],
                                    );
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
